import React, { useEffect, useState } from 'react';
import useWindowSize from '@rehooks/window-size';

import './TaxHoldWidget.scss';

import { getTaxHoldList, getTaxHoldValue } from '../../../../api/profile';
import styled from 'styled-components';
import { CircleLoader } from '../../../../ui';
import { animated, useSpring } from 'react-spring';
import formatNumber from '../../../../helpers/formatNumber';
import { ReactComponent as IconPig } from 'static/assets/icon-pig.svg';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';

export const TaxHoldWidget = () => {
  const windowSize = useWindowSize();
  const [taxHoldValue, setTaxHoldValue] = useState(null);
  const [taxHoldList, setTaxHoldList] = useState(null);

  const [page, setPage] = useState(1);
  const [hasLoadMore, setHasLoadMore] = useState(true);
  const [loading, setLoading] = useState(true);

  const onLoad = async () => {
    setLoading(true);
    await getTaxHoldList({}, page)
      .then((list) => {
        const mappedList = list.map((item) => {
          return {
            date: moment(item.registrationDate).format('DD.MM.YYYY'),
            info: item.comment,
            hold: (item.taxEstimated / 100).toFixed(2),
            sum: (item.amount / 100).toFixed(2),
          };
        });
        setTaxHoldList([...(taxHoldList ? taxHoldList : []), ...mappedList]);
        if (!list.length) {
          setHasLoadMore(false);
        } else {
          setPage(page + 1);
        }
      })
      .catch((a) => {
        setTaxHoldList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTaxHoldValue()
      .then(({ amount }) => {
        setTaxHoldValue(amount / 100);
      })
      .catch(() => {
        setTaxHoldValue(0);
      });
    onLoad();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transY = (y) => `translateY(${y}px)`;

  const spring = useSpring({
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 50 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  return (
    <animated.div
      className="tax-hold-widget"
      style={{
        opacity: spring.opacity,
        transform: spring.y.interpolate(transY),
      }}
    >
      {windowSize.innerWidth > 980 && <h2 className="tax-hold-widget__title">Налоговая копилка</h2>}
      <div className="tax-hold-widget__content-wrap">
        {!taxHoldList || taxHoldValue === null ? (
          <CircleLoaderStyled />
        ) : (
          <>
            <div className="tax-hold-widget__head">
              <div className="tax-hold-widget__head-block">
                <div className="tax-hold-widget__head-block-desc">Отложено на оплату</div>
                <div className="tax-hold-widget__head-block-value">
                  <div className="tax-hold-widget__head-block-value-rubles">
                    {Math.floor(taxHoldValue).toString()},
                  </div>
                  <div className="tax-hold-widget__head-block-value-penny">
                    {taxHoldValue.toFixed(2).slice(-2)} ₽
                  </div>
                </div>
              </div>

              <a
                className="tax-hold-widget__head-link"
                href="https://xn--80aapgyievp4gwb.xn--p1ai/blog/nalogovaya-kopilka-dlya-samozanyatyh"
                target="_blank"
                rel="noreferrer"
              >
                Как работает копилка?
              </a>
            </div>

            <div className="tax-hold-widget__content">
              <div className="tax-hold-widget__content-title">Операции</div>
              {!!taxHoldList?.length ? (
                <div className="tax-hold-widget__table">
                  <div className="tax-hold-widget__table-row head">
                    <div className="tax-hold-widget__table-row-date">Дата</div>
                    <div className="tax-hold-widget__table-row-info">Назначение</div>
                    <div className="tax-hold-widget__table-row-hold">Удержано</div>
                    <div className="tax-hold-widget__table-row-sum">Сумма выплаты</div>
                  </div>
                  <InfiniteScroll hasMore={hasLoadMore && !loading} loadMore={() => onLoad()}>
                    {taxHoldList.map((tax, i) => {
                      return (
                        <div className="tax-hold-widget__table-row" key={i}>
                          <div className="tax-hold-widget__table-row-date">{tax.date}</div>
                          <div className="tax-hold-widget__table-row-info">{tax.info}</div>
                          <div className="tax-hold-widget__table-row-hold">{tax.hold} ₽</div>
                          <div className="tax-hold-widget__table-row-sum">{tax.sum} ₽</div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                  {loading && <TableCircleLoaderStyled />}
                </div>
              ) : (
                <div className="tax-hold-widget__empty">
                  <div className="tax-hold-widget__empty-icon">
                    <IconPig />
                  </div>
                  <div className="tax-hold-widget__empty-text">Нет операций</div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </animated.div>
  );
};

TaxHoldWidget.propTypes = {};

TaxHoldWidget.defaultProps = {};

const TableCircleLoaderStyled = styled(CircleLoader)`
  margin: 20px auto;
`;

const CircleLoaderStyled = styled(CircleLoader)`
  margin: 143px auto;
`;
