import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, connect } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';

import { getPartnershipInfo } from '../../selectors';
import { changeLookOfPhone } from 'helpers';
import { showPopupEditBankCard } from '../../actions';

import styles from './PartnerAboutTab.module.scss';

const PartnerAboutTab = ({ dispatch }) => {
  const { partnershipId } = useParams();
  const partnership = useSelector(getPartnershipInfo(partnershipId));

  if (!partnership) {
    return <Redirect to="/lk/profile-settings/partners" />;
  }

  const infoFields = [];

  if (partnership.partner.fullName) {
    infoFields.push({
      title: 'Полное наименование',
      value: partnership.partner.fullName,
    });
  }

  if (partnership.startDate) {
    infoFields.push({
      title: 'Сотрудничество',
      value: `с ${partnership.startDate}`,
    });
  }

  if (partnership.partner.address) {
    infoFields.push({
      title: 'Адрес',
      value: partnership.partner.address,
    });
  }

  if (partnership.partner.inn) {
    infoFields.push({
      title: 'ИНН',
      value: partnership.partner.inn,
    });
  }

  if (partnership.partner.email) {
    infoFields.push({
      title: 'e-mail',
      value: partnership.partner.email,
    });
  }

  if (partnership.partner.phone) {
    infoFields.push({
      title: 'Телефон',
      value: changeLookOfPhone(partnership.partner.phone),
    });
  }

  if (partnership.maskedPan) {
    infoFields.push({
      title: 'Карта для выплат',
      value: partnership.maskedPan,
      onClick: changeCardNumber,
    });
  }

  if (!partnership?.maskedPan) {
    infoFields.push({
      title: 'Карта для выплат',
      value: 'Добавить карту',
      onValueClick: changeCardNumber,
    });
  }

  function changeCardNumber() {
    dispatch(showPopupEditBankCard(partnership.id));
  }

  return (
    <div className={styles.partnerAboutTab}>
      <div className={styles.partnerAboutTabInfo}>
        {infoFields.map((field, index) => (
          <div key={index} className={styles.partnerAboutTabInfoRow}>
            <div className={styles.partnerAboutTabInfoRowTitle}>{field.title}</div>
            <div className={styles.partnerAboutTabInfoRowValue}>
              {!field.onValueClick && <span>{field.value}</span>}
              {field.onValueClick && (
                <button onClick={field.onValueClick}>
                  <span>{field.value}</span>
                </button>
              )}
              {field.onClick && (
                <button onClick={field.onClick}>
                  <IconEdit />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

PartnerAboutTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(PartnerAboutTab);
