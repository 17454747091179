import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import QRCode from 'qrcode';
import useWindowSize from '@rehooks/window-size';

// Icons
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

// Misc
import {
  capitalizeFirstLetter,
  formatDateHuman,
  formatTimeHuman,
  formatTimeHumanCustomOffset,
} from 'helpers';
import formatNumber from 'helpers/formatNumber';

// Styles
import './Check.scss';

const Check = React.forwardRef((props, ref) => {
  const { className, checkData, closeCheckButtonClick, isCheckOpen, isOnDealsPage } = props;
  const windowSize = useWindowSize();

  const canvasRef = useRef();

  useEffect(() => {
    const refLocal = canvasRef.current;

    if (refLocal && checkData.link) {
      QRCode.toCanvas(checkData.link).then((canvas) => refLocal.appendChild(canvas));
    }

    return () => {
      if (refLocal) {
        refLocal.innerHTML = '';
      }
    };
  }, [checkData.link]);

  const operationDate = new Date(checkData.operationTime);
  const dateString = formatDateHuman(operationDate);
  const timeString = formatTimeHumanCustomOffset(operationDate, 180);
  const localOffset = new Date().getTimezoneOffset();
  const moscowOffset = -180;

  return (
    <div
      className={cx('check', {
        [className]: className,
        check_canceled: checkData.canceled,
        check_open: isCheckOpen,
      })}
      ref={ref}
      data-testid="check"
    >
      {windowSize.innerWidth <= 800 && isOnDealsPage && (
        <button className="check__close" type="button" onClick={closeCheckButtonClick}>
          <IconClose className="check__close-icon" />
        </button>
      )}

      <div className="check__block check__block_top">
        {checkData.id && <p className="check__number">Чек № {checkData.id}</p>}

        {checkData.operationTime && (
          <div className="check__date-wrap">
            <p className="check__date">{dateString}</p>
            <p className="check__time">
              {timeString} {localOffset === moscowOffset ? '' : '(Мск)'}
            </p>
          </div>
        )}
      </div>

      <div className="check__block check__block_personal-info">
        <p className="check__name">{`${capitalizeFirstLetter(
          checkData.lastName || '',
        )} ${capitalizeFirstLetter(checkData.firstName || '')} ${capitalizeFirstLetter(
          checkData.middleName || '',
        )}`}</p>
        {checkData.inn && <p className="check__inn">ИНН: {checkData.inn}</p>}
        {checkData.self && (
          <div className="check__sz-block">
            <p className="check__inn">Чек сформировал: Самозанятые.рф</p>
            <p className="check__inn">ИНН: 9731029523</p>
          </div>
        )}
        <p className="check__regime">Режим НО: НПД</p>
      </div>

      <div className="check__block check__block_main">
        <div className="check__block-header">
          <p className="check__block-header-title">Наименование</p>
          <p className="check__block-header-title">Сумма</p>
        </div>

        {checkData.services && Array.isArray(checkData.services) && checkData.services.length ? (
          <>
            {checkData.services.map((service) => (
              <div
                className="check__block-content-item"
                key={`${service.name}-${service.amount}-${service.quantity}`}
              >
                <p className="check__block-content-item-title">{service.name}</p>
                <p className="check__block-content-item-sum">
                  {formatNumber(service.amount / 100)} ₽
                </p>
              </div>
            ))}
          </>
        ) : (
          <>
            {checkData.serviceName && (
              <div className="check__block-content">
                <div className="check__block-content-item">
                  <p className="check__block-content-item-title">{checkData.serviceName}</p>
                  <p className="check__block-content-item-sum">
                    {formatNumber(checkData.totalAmount / 100)} ₽
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="check__block check__block_sum">
        <p className="check__sum-text check__sum-text_sum-title">Итого:</p>
        <p className="check__sum-text">{formatNumber(checkData.totalAmount / 100)} ₽</p>
      </div>

      {checkData.customerName && (
        <div className="check__block check__block_customer">
          <div className="check__block-header">
            <p className="check__block-header-title">Покупатель</p>
          </div>

          {checkData.customerInn && (
            <>
              <p className="check__inn">
                Юридическое лицо,{' '}
                <span className="check__customer-name">{checkData.customerName}</span>
              </p>
              <p className="check__inn">ИНН: {checkData.customerInn}</p>
            </>
          )}
          {!checkData.customerInn && (
            <>
              <p className="check__inn">
                Иностранная организация,{' '}
                <span className="check__customer-name">{checkData.customerName}</span>
              </p>
            </>
          )}
        </div>
      )}

      {!checkData.canceled && (
        <div className="check__qr">
          <div className="check__qr-inner" ref={canvasRef} />
        </div>
      )}

      <div className="check__bg">
        {new Array(50).fill(1).map((_item, index) => (
          /* eslint-disable react/no-array-index-key */
          <div className="check__bg-square" key={index} />
          /* eslint-enable react/no-array-index-key */
        ))}
      </div>
    </div>
  );
});

Check.propTypes = {
  className: PropTypes.string,
  checkData: PropTypes.shape({
    id: PropTypes.string,
    operationTime: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    inn: PropTypes.string,
    serviceName: PropTypes.string,
    totalAmount: PropTypes.number,
    link: PropTypes.string,
    canceled: PropTypes.bool,
    customerInn: PropTypes.string,
    customerName: PropTypes.string,
    checkAvailability: PropTypes.bool,
    self: PropTypes.bool,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        name: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ),
  }),
  closeCheckButtonClick: PropTypes.func,
  isCheckOpen: PropTypes.bool,
  isOnDealsPage: PropTypes.bool,
};

Check.defaultProps = {
  className: null,
  checkData: null,
  closeCheckButtonClick: () => {},
  isCheckOpen: false,
  isOnDealsPage: false,
};

export default Check;
