import { fetchWrapper } from 'helpers';

// получить список принятых партнеров
export const getPartnerList = async () => {
  const url = `${process.env.REACT_APP_API_URL}/partnerships`;
  const { data, result } = await fetchWrapper(url);

  if (!data) {
    throw new Error(result.message);
  }

  return data;
};

// получить список договоров, связанных с этим партнером
export const getPartnerDocumentList = async (platform, partner, page = 1) => {
  let url = `${process.env.REACT_APP_API_URL}/partnerships/documents?`;
  url += new URLSearchParams({
    platform,
    partner,
    page,
    pageSize: 50,
  });

  const { data, result } = await fetchWrapper(url);

  if (!data) {
    throw new Error(result.message);
  }

  return data;
};

// получить список выплат, связанных с партнером
export const getPartnerPaymentList = async (platform, partnerId, startDate, endDate, page = 1) => {
  let url = `${process.env.REACT_APP_API_URL}/partnerships/${platform}/partners/${partnerId}/payouts?`;
  url += new URLSearchParams({
    startDate,
    endDate,
    page,
    pageSize: 50,
  });
  const response = await fetchWrapper(url);

  const { data, result } = response;

  if (!data) {
    throw new Error(result.message);
  }

  const formatData = {
    ...data,
    payoutsSum: data.payoutsSum / 100,
    payouts: data.payouts.map((i) => ({
      ...i,
      totalAmount: i.totalAmount / 100,
      services: i.services.map((ii) => ({ ...ii, amount: ii.amount / 100 })),
      tax: i.tax / 100,
    })),
  };

  return formatData;
};

// подключить партнера
export const confirmPartnerRequest = async (cardNumber, platform, requestId) => {
  const url = `${process.env.REACT_APP_API_URL}/partnerships/${platform}/requests/${requestId}/confirm`;
  const response = await fetchWrapper(url, {
    method: 'POST',
    body: JSON.stringify({
      pan: cardNumber,
    }),
  });

  return response.data;
};

// отклонить запрос партнера
export const declinePartnerRequest = async (platform, requestId) => {
  const url = `${process.env.REACT_APP_API_URL}/partnerships/${platform}/requests/${requestId}/decline`;
  const response = await fetchWrapper(url, {
    method: 'POST',
  });

  return response.data;
};

// добавить банковскую карту для партнера
export const addPartnerBankCard = async (cardNumber, platform, partnerId) => {
  const url = `${process.env.REACT_APP_API_URL}/partnerships/${platform}/partners/${partnerId}/bankcard`;
  const response = await fetchWrapper(url, {
    method: 'PUT',
    body: JSON.stringify({
      pan: cardNumber,
    }),
  });

  return response.data;
};

// изменить банковскую карту для партнера
export const editPartnerBankCard = async (cardNumber, platform, partnershipId) => {
  const url = `${process.env.REACT_APP_API_URL}/partnerships/${platform}/${partnershipId}/bankcard`;
  const response = await fetchWrapper(url, {
    method: 'PUT',
    body: JSON.stringify({
      pan: cardNumber,
    }),
  });

  return response.data;
};

// получить статистику по выплатам
export const getPartnerPaymentStats = async (platform, partnershipId) => {
  const url = `${process.env.REACT_APP_API_URL}/partnerships/${platform}/partners/${partnershipId}/payouts/statistic`;
  const response = await fetchWrapper(url);

  return response.data;
};
